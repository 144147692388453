import { AbilityBuilder, Ability } from '@casl/ability';
import {
  roleHierarchy, superAdminRole, forwoodIdAdminRole, siteAdminRole,
} from '../Utils/RoleHierarchyData';
import { containsAtLeastOne } from '../Utils/Utils';

/**
 * Defines how to detect object's type:
 * https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }

  return item.__type;
}

const hasGroupAccess = (groups, groupName) => (Array.isArray(groups) ? groups.includes(groupName) : false);
const hasGroupAccessList = (groups, groupName) => (Array.isArray(groups) ? groups.some(gr => (groupName ? groupName.includes(gr) : false)) : false);

const isSuperAdmin = groups => hasGroupAccess(groups, superAdminRole);
const isForwoodIdAdmin = groups => hasGroupAccess(groups, forwoodIdAdminRole);
const isSiteAdmin = groups => hasGroupAccess(groups, siteAdminRole);

export const accessRoles = [];

function defineAbility(groups, enableSiteAdminBatchProcess) {
  return AbilityBuilder.define({ subjectName }, ((can) => {
    if (isSuperAdmin(groups) || isForwoodIdAdmin(groups)) {
      can(['list', 'manage', 'bulkUpdate', 'batchprocess', 'export'], 'Users');
    } else if (isSiteAdmin(groups)) {
      const userOps = ['list', 'manage', 'bulkUpdate', 'export'];
      if (enableSiteAdminBatchProcess) {
        userOps.push('batchprocess');
      }
      can(userOps, 'Users');
    }

    if (isSuperAdmin(groups) || isForwoodIdAdmin(groups)) {
      can(['create', 'edit'], 'Apps');
      can(['filter'], 'Companies');
      can(['filter'], 'Sites');
    }

    for (const role of accessRoles) {
      if (hasGroupAccess(groups, role)) {
        can(['access'], role);
      }
    }
  }));
}

// defineAbilityForUser has the ability to define permissions for both
// CognitoUser auth objects and user objects returned via GraphQL query.
export function defineAbilityForUser(user, enableSiteAdminBatchProcess) {
  if (!user) {
    return null;
  }

  if (!user.getSignInUserSession) {
    // graphql user object
    if (user.roles) {
      return defineAbility(user.roles, enableSiteAdminBatchProcess);
    }
    return null;
  }

  // cognito auth object
  return defineAbility(
    user.getSignInUserSession().getAccessToken().decodePayload()['cognito:groups'],
    enableSiteAdminBatchProcess,
  );
}

// Simple 'Can Edit User' permission check, accepts currentUser object,
// comparedUser object and a list of the current users available companies.
// (user objects need only email and roles array properties present)
// @TODO update or remove this method as we are no longer required to do this as our backend is already secured base on the user companyStructure
export function canEditUser(currentUser, comparedUser, companies) {
  if (!currentUser || !currentUser.roles || currentUser.roles.length < 1 || !companies) {
    return false;
  }

  // 'users cannot edit themselves' unless they are forwoodIdAdmin or SiteAdmin role
  // (note: will change with introduction of 'Super Admin' in future tickets
  if (currentUser.email === comparedUser.email && !containsAtLeastOne(currentUser.roles, [superAdminRole, forwoodIdAdminRole, siteAdminRole])) {
    return false;
  }

  return hasHigherRole(currentUser.roles, comparedUser.roles);
}

export function canTransferUser(currentUser, comparedUser) {
  if (!currentUser || !currentUser.roles || currentUser.roles.length < 1) {
    return false;
  }

  return hasHigherRole(currentUser.roles, comparedUser.roles);
}

// Does first array of roles contain a higher role than second, based on application RoleHierarchy
export function hasHigherRole(roles, comparedRoles) {
  if (!roles || roles.length < 1) return false;

  // Compare currentUser & comparedUser roles against application roles hierarchy top down.
  for (let i = 0; i < roleHierarchy.length; i++) {
    if (roles.includes(roleHierarchy[i])) {
      return true; // If current user has role, they are higher (return/break loop)
    } if (comparedRoles && comparedRoles.includes(roleHierarchy[i])) {
      return false; // If they don't but compared user does, they are lower (return/break loop)
    }
    // If neither condition met, continue looping role hierarchy checking each level
  }

  return false; // Default return false (no matched ForwoodId hierarchy roles on either user)
}

// DACs can only be generated for users with CRM access
export function canHaveDacGenerated(user, crmAccessRoles) {
  return hasGroupAccessList(user.roles, crmAccessRoles);
}

// Export a default empty ability for unauthenticated users
// This won't be used for now
export default new Ability([]);
