import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import PropTypes from 'prop-types';
import {
  Button, Input, Icon, Form, Card, Divider,
} from 'antd';
import { withTranslation } from 'react-i18next';
import AuthWrapper from './AuthWrapper';
import styles from './styles.scss';

class ForwoodRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
    };

    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
    this.backToSignIn = this.backToSignIn.bind(this);
    this.resetErrorState = this.resetErrorState.bind(this);
  }

  error(error) {
    const { t } = this.props;
    let message;
    if (error === 'Password cannot be empty') { // Amplify exception
      message = t('login:PasswordEmptyException');
    } else {
      switch (error.code) {
        case 'LimitExceededException':
          message = t('common:LimitExceededException');
          break;
        case 'InvalidPasswordException':
        case 'InvalidParameterException':
          message = t('forgotPassword:InvalidPasswordException');
          break;
        default:
          message = t('common:GenericException');
      }
    }
    this.setState({ error: message });
    this.loading(false);
  }

  resetErrorState() {
    this.setState({ error: null });
  }

  compareToFirstPassword(rule, value, callback) {
    const { form, t } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(t('forgotPassword:PasswordMisMatchException'));
    } else {
      callback();
    }
  }

  superChange() {
    super.change();
  }

  change(e) {
    e.preventDefault();

    const { form } = this.props;
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        this.loading();
        this.resetErrorState();
        this.superChange();
      }
    });
  }

  backToSignIn() {
    this.resetErrorState();
    this.changeState('signIn');
  }

  loading(state = true) {
    this.setState({ loading: state });
  }

  showComponent() {
    const {
      hide, t, form, customBrandingConfig, logoUrl,
    } = this.props;
    const { error, loading } = this.state;
    const { getFieldDecorator } = form;

    if (hide && hide.includes(ForwoodRequireNewPassword)) {
      return null;
    }

    return (
      <AuthWrapper
        error={error}
        loading={loading}
        customBrandingConfig={customBrandingConfig}
        logoUrl={logoUrl}
      >
        <Form>
          <Form.Item>
            <Card>
              <Divider className={styles.divider}>
                <span className={styles.title}>
                  {t('changePassword')}
                </span>
              </Divider>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{
                    required: true,
                    message: t('login:PasswordEmptyException'),
                  },
                  {
                    pattern: /^(?=.*\d)(?=.*[\^$*.{}()?"!@#%&,><':;|[_\]~`=+-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: t('login:PasswordRequirenmentMessage'),
                  }],
                })(
                  <Input
                    prefix={<Icon type="lock" className={styles.formIcon} />}
                    placeholder={t('forgotPassword:newPassword')}
                    key="password"
                    name="password"
                    type="password"
                    onChange={this.handleInputChange}
                  />,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('confirm password', {
                  rules: [
                    {
                      required: true,
                      message: t('login:ConfirmPasswordEmptyException'),
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" className={styles.formIcon} />}
                    placeholder={t('forgotPassword:confirmNewPassword')}
                    key="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    onFocus={this.makeEditable}
                    readOnly={this.state.readOnly}
                  />,
                )}
              </Form.Item>
              <Form.Item className="no-margin-bottom">
                <Button
                  type="primary"
                  block
                  onClick={this.change}
                  tabIndex={1}
                  htmlType="submit"
                >
                  {t('changePassword')}
                </Button>
              </Form.Item>
            </Card>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="secondary"
              onClick={this.backToSignIn}
            >
              {t('login:backToLogin')}
            </Button>
          </Form.Item>
        </Form>
      </AuthWrapper>
    );
  }
}
ForwoodRequireNewPassword.propTypes = {
  logoUrl: PropTypes.string,
};

ForwoodRequireNewPassword.defaultProps = {
  logoUrl: null,
};


export default withTranslation(['requireNewPassword', 'login', 'forgotPassword'])(Form.create()(ForwoodRequireNewPassword));
