import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import {
  Spin, Row, Col, Modal,
} from 'antd';
import { withTranslation } from 'react-i18next';
import Config from '../../Config/Config';
import { buildURL } from '../../Utils/Utils';
import { loader } from '../../Sass/modules/loader.scss';

class QuicksightLogin extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);

    this.state = {
      errorMessage: null,
      quicksightRedirectUri: query.get('qs_redirect_uri') || this.defaultQuicksightRedirectUri,
    };

    this.retry = this.retry.bind(this);
  }

  get defaultQuicksightRedirectUri() {
    const baseUrl = encodeURIComponent('https://quicksight.aws.amazon.com/');
    const protocol = Config.reactApp.SECURE_PROTOCOL ? 'https://' : 'http://';
    return buildURL({
      session_timeout_redirect_url: `${protocol}${Config.reactApp.HOSTNAME}/quicksight-login`,
    }, baseUrl);
  }

  get enableRetry() {
    return this.props.enabled;
  }

  componentDidMount() {
    const { t } = this.props;
    if (!this.props.enabled) {
      return this.displayError(t('disabledService'));
    }

    this.redirectToQuicksight();
  }

  resetErrorState() {
    this.setState({ errorMessage: null });
  }

  displayError(errorMessage) {
    const { t } = this.props;
    errorMessage = errorMessage || t('unexpectedErrorMessage');

    this.setState({ errorMessage }, () => {
      this.showError(errorMessage);
    });
  }

  retry() {
    this.resetErrorState();
    this.redirectToQuicksight();
  }

  async redirectToQuicksight() {
    const { t } = this.props;
    try {
      const cognitoUserSession = await Auth.currentSession();
      const idToken = cognitoUserSession.getIdToken();
      const jwtToken = idToken.getJwtToken();
      const url = await this.getAwsFederationSigninUrl(jwtToken);
      window.location.replace(url);
    } catch (e) {
      if (String(e).toString().includes('Permission denied')) {
        this.displayError(t('noPermissionService'));
      } else {
        // Generic error message
        this.displayError();
      }
    }
  }

  async getAwsFederationSigninUrl(jwtToken) {
    // On-time provision for quicksight user
    const response = await fetch(
      `${Config.apiGateway.URL}/get-aws-federation-signin-url?destination=${this.state.quicksightRedirectUri}`, {
        method: 'GET',
        headers: {
          Authorization: jwtToken,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      if (response.status === 403) {
        throw Error('Permission denied!');
      }
      throw Error('An unexpected error happened');
    }

    const jsonData = await response.json();

    return jsonData.data.url;
  }

  showError(err) {
    const { t } = this.props;
    Modal.error({
      title: t('quicksight:quicksightError'),
      content: err,
      keyboard: false,
      centered: true,
      okText: 'Retry',
      okButtonProps: { disabled: !this.enableRetry },
      onOk: () => this.enableRetry && this.retry(),
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Spin
        spinning={!this.state.errorMessage}
        size="large"
        className={loader}
        tip={t('quicksight:redirecting')}
      >
        <Row><Col /></Row>
      </Spin>
    );
  }
}

QuicksightLogin.propTypes = {
  enabled: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation(['common'])(QuicksightLogin);
