import gql from 'graphql-tag';

export default gql(`
query($id: String!, $status: String, $includeSubLevelCompanies: Boolean, $traverseSiteStructure: Boolean) {
  getPhysicalLocations(
    companyId: $id,
    status: $status,
    includeSubLevelCompanies: $includeSubLevelCompanies,
    traverseSiteStructure: $traverseSiteStructure
  ) {
    id
    name
    inactive
    historical
    archived
  }
}`);
