import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Spin, notification,
} from 'antd';
import classNames from 'classnames';

import forwoodLogo from '../../Images/forwood-logo-blue-on-light.svg';
import Version from '../../Containers/Version';
import styles from './styles.scss';
import { loader } from '../../Sass/modules/loader.scss';

export default class AuthWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.notificationKey = 'authWrapper';
  }

  get styleBlock() {
    /*
      Heads up! The styles below are necessary for the correct render of this component.
      The main idea is that all the elements up to the `Grid` below must have a height of 100%.
      We don't move this into an css file as we only need this for LoginUI.
    */
    const styles = `
      body > div#root,
      body > div#root > div,
      body > div#root > div.ant-row-flex,
      body > div#root div.ant-spin-nested-loading,
      body > div#root div.ant-spin-container,
      body > div#root > div > div.login-form {
        height: 100%;
      }`;

    return `${styles}${this.customStyleBlock}`;
  }

  get customStyleBlock() {
    if (!this.hasCustomBranding) {
      return '';
    }

    const { customBrandingConfig } = this.props;

    // This style block also includes client branding colour overrides (if supplied)
    // that are only needed for the pre authentication views.
    // The ideal solution would be to inject overriding antd primary and secondary
    // colours through webpack, however this is not possible without ejecting
    // the create-react-app project. Including the overriding styles in this
    // way is the simplest solution at the moment.
    return `
      .ant-btn.ant-btn-primary.ant-btn-block {
        background: ${customBrandingConfig.primary_hex};
        border-color: ${customBrandingConfig.primary_hex};
      }
      .ant-divider-inner-text span {
        color: ${customBrandingConfig.secondary_hex};
      }
    `;
  }

  get hasCustomBranding() {
    const { customBrandingConfig } = this.props;
    return Object.entries(customBrandingConfig).length > 0;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== this.props.loading) {
      this.setState({
        loading: nextProps.loading,
      });
    }

    if (!nextProps.error) {
      this.hideError();
      return;
    }

    if (nextProps.error && nextProps.error !== this.props.error) this.showError(nextProps.error);
  }

  componentWillUnmount() {
    this.hideError();
  }

  showError(err) {
    // hide the active error message first
    this.hideError();

    notification.error({
      className: styles.authNotification,
      description: err,
      duration: 0,
      key: this.notificationKey,
    });

    this.setState({ loading: false });
  }

  hideError() {
    notification.close(this.notificationKey);
  }

  render() {
    const {
      logoUrl,
    } = this.props;

    return (
      <div className="login-form">
        <style>{this.styleBlock}</style>
        <Spin
          spinning={this.state.loading || this.props.loading}
          size="large"
          className={loader}
        >
          <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
            <Col xs={22} sm={20} md={12} lg={12}>
              <Row type="flex" justify="center" align="middle">
                <Col style={{ width: 450 }} type="flex" justify="center" align="middle">
                  <h2 className={classNames('text-center', styles.forwoodLogo, { [styles.reducedLogo]: this.hasCustomBranding })}>
                    { logoUrl ? (
                      <img src={logoUrl} alt="Logo" />
                    ) : (
                      <>
                        <img src={forwoodLogo} width="50" alt="Forwood ID Logo" />
                        Forwood
                      </>

                    )}
                  </h2>
                  {this.props.children}
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Version />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

AuthWrapper.propTypes = {
  loading: PropTypes.bool,
  customBrandingConfig: PropTypes.object,
  logoUrl: PropTypes.string,
};

AuthWrapper.defaultProps = {
  customBrandingConfig: {},
  logoUrl: null,
};
