import React from 'react';
import PropTypes from 'prop-types';
import { SignIn } from 'aws-amplify-react';
import { withTranslation } from 'react-i18next';
import { Hub } from '@aws-amplify/core';
import {
  Card, Form, Icon, Input, Button, Select, Divider,
} from 'antd';
import { languageOptions, getCurrentLanguage } from '../../Translations/LanguageOptions';
import AuthWrapper from './AuthWrapper';
import Config from '../../Config/Config';
import styles from './styles.scss';
import { storeCookieValue } from '../../Utils/CookieData';

class ForwoodSignIn extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.federatedLogin = this.federatedLogin.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  // Override for default Amplify error handler
  // Any specific Cognito auth errors requiring translation must be handled here
  error(error) {
    const { t } = this.props; // i18next withTranslation function
    let message;
    if (error === 'Username cannot be empty') { // Amplify exception
      message = t('login:UsernameEmptyException');
    } else {
      switch (error.code) { // Cognito exceptions
        case 'UserLambdaValidationException':
          if (error.message.includes('INVALID_USER')) {
            message = t('login:NotAuthorizedException');
          } else {
            message = t('login:GenericAuthException');
          }
          break;
        case 'UserNotFoundException':
          message = t('login:UserNotFoundException');
          break;
        case 'NotAuthorizedException':
          if (error.message === 'User is disabled.') {
            message = t('login:AccountDisabledException');
          } else if (error.message === 'User is not authorized to get auth details.') {
            message = t('login:NotAuthorizedToGetAuthDetails');
          } else if (error.message === 'Incorrect username or password.') {
            message = t('login:NotAuthorizedException');
          } else if (error.message === 'Password attempts exceeded') {
            message = t('login:attemptsExceeded');
          } else if (error.message === 'Temporary password has expired and must be reset by an administrator.') {
            message = t('login:TemporaryPasswordExpired');
          } else {
            message = t('login:GenericAuthException');
          }
          break;
        case 'UnexpectedLambdaException': // deal with very awful Amplify empty password error
          if (error.message === 'null failed with error Generate callenges lambda cannot be called..') {
            message = t('login:PasswordEmptyException');
          } else {
            message = t('login:GenericAuthException');
          }
          break;
        case 'InvalidParameterException':
          if (error.message.includes('validation error') && error.message.includes('userName')) {
            message = t('common:InvalidUsernameException');
          } else {
            message = t('login:GenericAuthException');
          }
          break;
        default:
          message = t('login:GenericAuthException');
      }
    }
    this.setState({ error: message });
  }

  get hasPrimaryIdentityProvider() {
    const { identityProvider } = this.props;
    return identityProvider !== null && identityProvider !== undefined && identityProvider !== '';
  }

  get showFederatedLoginButton() {
    const { ssoEnabled } = this.props;
    return ssoEnabled && this.hasPrimaryIdentityProvider;
  }

  federatedLogin() {
    this.resetErrorState();
    this.setState({ loading: true });
    Hub.dispatch('forwood', { event: 'federatedLogin' });
    // Launch hosted UI
    window.location.assign(`${Config.cognito.USERPOOL_ADFS_LAUNCH_URL}&identity_provider=${this.props.identityProvider}`);
  }

  resetErrorState() {
    this.setState({ error: null });
  }

  signIn(e) {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        this.resetErrorState();
        this.inputs.username = this.inputs.username.trim().toLowerCase();
        this.setState({ loading: true });
        super.signIn();
      }
    });
  }

  changeState(state, data) {
    if (this.unmounted) {
      return;
    }

    super.changeState(state, data);
    this.setState({ loading: false });
  }

  changeLanguage(value) {
    this.props.i18n.changeLanguage(value);
    storeCookieValue('tempLocale', value, window.location.hostname);
  }

  showComponent() {
    const {
      hide = [], t, i18n, form, customBrandingConfig = {}, logoUrl,
    } = this.props;
    const { getFieldDecorator } = form;
    if (hide && hide.includes(ForwoodSignIn)) {
      return null;
    }

    const { error, loading } = this.state;
    const options = languageOptions.map(lang => (
      <Select.Option key={lang.key}>
        {t(lang.translationKey)}
      </Select.Option>
    ));

    let selectedLanguageKey = null;
    if (getCurrentLanguage(i18n.languages)) {
      selectedLanguageKey = getCurrentLanguage(i18n.languages).key;
    }

    return (
      <AuthWrapper
        error={error}
        loading={loading || this.props.loading}
        customBrandingConfig={customBrandingConfig}
        logoUrl={logoUrl}
      >
        {/*
        Necessary override for this component only. If the user is on a short
        screen we need to override the `height: 100%` rule set in AuthWrapper
        otherwise lower parts of the form become completely inaccessible.
      */}
        <style>
          {`
        @media (max-height: 679px) {
          body > div#root,
          body > div#root > div,
          body > div#root > div.ant-row-flex,
          body > div#root div.ant-spin-nested-loading,
          body > div#root div.ant-spin-container,
          body > div#root > div > div.login-form {
            height: auto !important;
          }
        }
      `}
        </style>
        <Form onSubmit={this.signIn}>
          {
            this.showFederatedLoginButton && (
              <Form.Item>
                <Card>
                  <Divider className={styles.divider}>
                    <span className={styles.title}>
                      {t('signInFederated')}
                    </span>
                  </Divider>
                  <Button
                    block
                    id="sso-signin-button"
                    type="primary"
                    tabIndex={1}
                    className={styles.ssoLoginBtn}
                    onClick={this.federatedLogin}
                    style={{ backgroundColor: customBrandingConfig.sso_button_background_hex && customBrandingConfig.sso_button_background_hex }}
                  >
                    {customBrandingConfig.logo_url && (
                      <span className={styles.clientLogo}>
                        <img
                          src={customBrandingConfig.logo_url}
                          width={customBrandingConfig.logo_width || 100}
                          alt="custom-logo"
                        />
                      </span>
                    )}
                    <span style={{
                      color: customBrandingConfig.sso_login_text_hex,
                      top: customBrandingConfig.sso_login_text_margin && parseInt(customBrandingConfig.sso_login_text_margin),
                      position: customBrandingConfig.sso_login_text_margin && 'relative',
                    }}
                    >
                      {t(customBrandingConfig.logo_url ? 'logIn' : 'federatedButtonText')}
                    </span>
                  </Button>
                </Card>
              </Form.Item>
            )
          }
          <Form.Item>
            <Card>
              <Divider className={styles.divider}>
                <span className={styles.title}>
                  {t('signInNormal')}
                </span>
              </Divider>
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [{
                    required: true, message: t('login:UsernameEmptyException'),
                  }],
                })(
                  <Input
                    prefix={<Icon type="user" className={styles.formIcon} />}
                    name="username"
                    key="username"
                    placeholder={t('usernamePlaceholder')}
                    onChange={this.handleInputChange}
                    tabIndex={2}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{
                    required: true, message: t('login:PasswordEmptyException'),
                  }],
                })(
                  <Input
                    prefix={<Icon type="lock" className={styles.formIcon} />}
                    name="password"
                    key="password"
                    type="password"
                    placeholder={t('password')}
                    onChange={this.handleInputChange}
                    tabIndex={3}
                    autoComplete="off"
                  />,
                )}
              </Form.Item>
              <Button
                block
                type="primary"
                tabIndex={4}
                onClick={this.signIn}
                htmlType="submit"
              >
                {t('logIn')}
              </Button>
            </Card>
          </Form.Item>
          <Form.Item>
            <Card>
              <Divider className={styles.divider}>
                <span className={styles.title}>
                  {t('language:language')}
                </span>
              </Divider>
              <Select
                id="language"
                name="language"
                placeholder={t('language:language')}
                defaultValue={selectedLanguageKey}
                onChange={this.changeLanguage}
                tabIndex={5}
              >
                {options}
              </Select>
            </Card>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="secondary"
              onClick={() => this.changeState('forgotPassword')}
              tabIndex={6}
            >
              {t('forgotPassword')}
            </Button>
          </Form.Item>
        </Form>
      </AuthWrapper>
    );
  }
}

ForwoodSignIn.propTypes = {
  loading: PropTypes.bool,
  ssoEnabled: PropTypes.bool,
  clientLogoUrl: PropTypes.string,
  identityProvider: PropTypes.string,
  logoUrl: PropTypes.string,
};

ForwoodSignIn.defaultProps = {
  logoUrl: null,
};


export default withTranslation(['login', 'language'])(Form.create()(ForwoodSignIn));
