const customMap = {
  '/authorize': 'Forwood ID Authorizer',
  '/quicksight-login': 'Quicksight',
  '/dac': 'DAC',
  crm: 'CRM',
  mapreport: 'MapReport',
  geoeditor: 'Geoeditor',
  safetyapps: 'SafetyApps',
};

export function getSiteName(redirectUri) {
  const customKeys = Object.keys(customMap);
  for (const key of customKeys) {
    if (redirectUri.includes(key)) {
      return customMap[key];
    }
  }

  // If not found return original redirect URL
  return redirectUri;
}
