import {
  storeCookieLang as StoreCookieLang, storeCookieValue as StoreCookieValue,
  getCookieLangFromID as GetCookieLangFromID, getCookieKeyForLocale as GetCookieKeyForLocale,
  getCookieLangdomain as GetCookieLangdomain, getCookieValue as GetCookieValue, clearCookie as ClearCookie,

} from 'componentlibrary';

export function storeCookieLang(key, lang, domain) {
  return StoreCookieLang(key, lang, domain);
}

export function getCookieLangFromID(key) {
  return GetCookieLangFromID(key);
}

export function getCookieKeyForLocale(username) {
  return GetCookieKeyForLocale(username);
}

export function getCookieLangdomain(key) {
  return GetCookieLangdomain(key);
}

export function storeCookieValue(key, value, domain) {
  return StoreCookieValue(key, value, domain);
}

export function getCookieValue(key) {
  return GetCookieValue(key);
}

export function clearCookie(key, domain) {
  return ClearCookie(key, domain);
}
