import QueryGetContractorCompanies from '../GraphQL/Query-GetContractorCompanies';

const CONTRACTOR_COMPANY_CLEAR_ACTION = 'CONTRACTOR_COMPANY_CLEAR_ACTION';
const CONTRACTOR_COMPANY_DATA_SET_ACTION = 'CONTRACTOR_COMPANY_DATA_SET_ACTION';

export function contractorCompanyDefaultState() {
  return {
    contractorCompanies: [],
    status: undefined,
  };
}

export async function getContractorCompanies(dispatch, client, searchName, company, fetchPolicy = 'network-only') {
  if (searchName && company) {
    const { data } = await client.query({
      query: QueryGetContractorCompanies,
      fetchPolicy,
      variables: { searchName, company },
    });
    const parseData = data.getContractorCompanies && data.getContractorCompanies.data ? JSON.parse(data.getContractorCompanies.data) : {};
    const status = data.getContractorCompanies && data.getContractorCompanies.status ? JSON.parse(data.getContractorCompanies.status) : undefined;
    dispatch({
      type: CONTRACTOR_COMPANY_DATA_SET_ACTION,
      contractorCompanies: parseData.contractorCompanies,
      status,
    });
  } else {
    // If searchName or company is empty, you might want to dispatch an action to handle this case.
    // For example, clear the contractor companies in the store.
    dispatch({
      type: CONTRACTOR_COMPANY_CLEAR_ACTION,
    });
  }
}


export function clearContractorCompanies(dispatch) {
  dispatch({
    type: CONTRACTOR_COMPANY_CLEAR_ACTION,
  });
}

export default (state = contractorCompanyDefaultState(), action) => {
  switch (action.type) {
    case CONTRACTOR_COMPANY_CLEAR_ACTION:
      return contractorCompanyDefaultState();

    case CONTRACTOR_COMPANY_DATA_SET_ACTION:
      const { contractorCompanies, status } = action;
      state.contractorCompanies = contractorCompanies;
      state.status = status;
      return {
        ...state,
      };

    default:
      return state;
  }
};
