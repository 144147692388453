import { connect } from 'react-redux';

import QuicksightLogin from '../../Components/QuicksightLogin';
import { getIn } from '../../Utils/Utils';

const mapStateToProps = (state, ownProps) => {
  const disabledRoles = getIn(state, ['appConfig', 'disabledRoles'], []);
  return {
    enabled: !disabledRoles.includes('QuickSightAccess'),
    ...ownProps,
  };
};

export default connect(mapStateToProps)(QuicksightLogin);
