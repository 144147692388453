import Auth from '@aws-amplify/auth';
import QueryRdsGetCurrentAuthenticatedUser from '../GraphQL/Query-RdsGetCurrentAuthenticatedUser';
import { getIn } from '../Utils/Utils';
import { storeLocale } from '../Utils/LocaleData';
import { convertIdTokenPayloadToUserObject, refreshIdToken } from '../Utils/Auth';
import { clearLocaleSelection } from '../Translations/i18n';
import { getCompanyDataForCurrentUser } from './company';
import { getPhysicalLocations } from './physicalLocation';
import { getContractorCompanies } from './contractorCompany';

const AUTH_SET_ACTION = 'AUTH_SET';
const AUTH_CLEAR_ACTION = 'AUTH_CLEAR';

const AUTH_CLIENT_SET_ACTION = 'AUTH_CLIENT_SET';
const AUTH_CLIENT_CLEAR_ACTION = 'AUTH_CLIENT_CLEAR';

const AUTH_CURRENT_USER_SET_ACTION = 'AUTH_CURRENT_USER_SET';
const AUTH_CURRENT_USER_CLEAR_ACTION = 'AUTH_CURRENT_USER_CLEAR';

const AUTH_LOCALE_SET_ACTION = 'AUTH_LOCALE_SET';

const appDefinedSessionStorageKeys = ['allusers.filter', 'allusers.companyStructureId', 'allusers.physicalLocationId'];

export function authDefaultState() {
  return {
    authState: '',
    authData: {},
    currentUser: {},
    client: null,
    locale: null,
  };
}

export const USER_LOGOUT_ACTION = 'USER_LOGOUT';

const COGNITO_SIGNED_IN_STATE = 'signedIn';

export function setAuth(dispatch, authState, authData) {
  dispatch({
    type: AUTH_SET_ACTION,
    authState,
    authData,
  });

  // Set current user base on IdToken
  setCurrentUser(dispatch, convertIdTokenPayloadToUserObject(
    getIn(authData, ['signInUserSession', 'idToken', 'payload'], {}),
  ));
}

export function getIdToken(authData) {
  return authData.idToken;
}

export function clearAuth(dispatch) {
  dispatch({
    type: AUTH_CLEAR_ACTION,
  });

  window.onbeforeunload = null;
  appDefinedSessionStorageKeys.forEach((key) => {
    sessionStorage.removeItem(key);
  });

  clearLocaleSelection();
  clearAuthClient(dispatch);
  clearCurrentUser(dispatch);
  userLogout(dispatch);
}

export function setAuthClient(dispatch, client) {
  dispatch({
    type: AUTH_CLIENT_SET_ACTION,
    client,
  });
}

export function clearAuthClient(dispatch) {
  dispatch({
    type: AUTH_CLIENT_CLEAR_ACTION,
  });

  clearCurrentUser(dispatch);
}

export async function getCurrentUser(dispatch, client) {
  let requestResponse = null;
  try {
    requestResponse = await client.query({
      query: QueryRdsGetCurrentAuthenticatedUser,
      fetchPolicy: 'network-only',
    });
  } catch (error) {
    if (error.graphQLErrors
      && error.graphQLErrors[0]
      && error.graphQLErrors[0].message
      && error.graphQLErrors[0].message === 'The requested user does not exist') {
      requestResponse = null;
    } else {
      throw error;
    }
  }

  const currentUser = getIn(requestResponse, ['data', 'rdsGetCurrentAuthenticatedUser']);

  // No user found in RDS. Skipped as setAuth will set currentUser base on authData
  if (!currentUser) {
    // skipped
    return;
  }

  setCurrentUser(dispatch, currentUser);

  return currentUser;
}

export async function refreshUserAuth(dispatch, client, payload, refetchCompanyData) {
  return new Promise((resolve) => {
    setCurrentUser(dispatch, payload);
    refreshIdToken(async () => {
      if (refetchCompanyData) {
        await getCompanyDataForCurrentUser(dispatch, client);
        await getContractorCompanies(dispatch, client);
        await getPhysicalLocations(dispatch, client, payload.companyStructure);
        sessionStorage.removeItem('allusers.filter');
        sessionStorage.removeItem('allusers.companyStructureId');
        sessionStorage.removeItem('allusers.physicalLocationId');
      }

      resolve(true);
    });
  });
}

export async function refreshUserTokens(dispatch) {
  return new Promise(async (resolve) => {
    let currentUser = null;
    refreshIdToken(async () => {
      try {
        currentUser = await Auth.currentAuthenticatedUser();
      } catch (err) {
        // Sign out on auth failure (e.g. expired refresh token)
        await Auth.signOut();
        clearAuth(dispatch);
      }

      setAuth(dispatch, COGNITO_SIGNED_IN_STATE, currentUser);
      const session = currentUser.signInUserSession;

      resolve(
        {
          idToken: session.idToken.jwtToken,
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        },
      );
    });
  });
}

export function setCurrentUser(dispatch, currentUser) {
  dispatch({
    type: AUTH_CURRENT_USER_SET_ACTION,
    currentUser,
  });
}

export function clearCurrentUser(dispatch) {
  dispatch({
    type: AUTH_CURRENT_USER_CLEAR_ACTION,
  });
}

export function userLogout(dispatch) {
  dispatch({
    type: USER_LOGOUT_ACTION,
  });
}

export function setLocale(locale, dispatch = null) {
  storeLocale(locale);

  if (dispatch) {
    dispatch({
      type: AUTH_LOCALE_SET_ACTION,
      locale,
    });
  }
}

export default (state = authDefaultState(), action) => {
  switch (action.type) {
    case AUTH_SET_ACTION:
      const { authState, authData } = action;
      return {
        ...state,
        authData,
        authState,
      };

    case AUTH_CLEAR_ACTION:
      return {
        ...state,
        authState: 'signedOut',
        authData: null,
      };

    case AUTH_CLIENT_SET_ACTION:
      const { client } = action;
      return {
        ...state,
        client,
      };

    case AUTH_CLIENT_CLEAR_ACTION:
      const existingClient = state.client;
      if (existingClient && existingClient.cache) {
        existingClient.cache.reset();
      }
      return {
        ...state,
        client: {},
      };

    case AUTH_CURRENT_USER_SET_ACTION:
      const { currentUser } = action;
      return {
        ...state,
        currentUser,
      };

    case AUTH_LOCALE_SET_ACTION:
      const { locale } = action;
      return {
        ...state,
        locale,
      };

    case AUTH_CURRENT_USER_CLEAR_ACTION:
      return {
        ...state,
        currentUser: {},
      };

    default:
      return state;
  }
};
