import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import { createApiMiddleware, wsMiddleware } from 'componentlibrary';
import rootReducer from '../reducers';
import { authDefaultState } from '../reducers/auth';
import { companyDefaultState } from '../reducers/company';
import { physicalLocationDefaultState } from '../reducers/physicalLocation';
import { rolesDefaultState } from '../reducers/roles';
import { isDevelopmentMode } from '../Utils/Utils';
import { microFrontendDefaultState } from '../reducers/microFrontend';
import { contractorCompanyDefaultState } from '../reducers/contractorCompany';

export const initialStore = {
  auth: authDefaultState(),
  company: companyDefaultState(),
  contractorCompany: contractorCompanyDefaultState(),
  physicalLocation: physicalLocationDefaultState(),
  roles: rolesDefaultState(),
  microFrontend: microFrontendDefaultState(),
};

const middlewares = [thunk, createApiMiddleware, wsMiddleware];

if (isDevelopmentMode()) {
  middlewares.push(logger);
}

middlewares.forEach((mw, index) => {
  if (typeof mw !== 'function') {
    middlewares.splice(index, 1);
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  rootReducer,
  initialStore,
  applyMiddleware(...middlewares),
);
