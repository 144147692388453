import { combineReducers } from 'redux';
import { appConfig } from 'componentlibrary';
import auth, { USER_LOGOUT_ACTION } from './auth';
import company from './company';
import physicalLocation from './physicalLocation';
import roles from './roles';
import identityProvider from './identityProvider';
import microFrontend from './microFrontend';
import contractorCompany from './contractorCompany';

const appReducer = combineReducers({
  appConfig,
  auth,
  company,
  physicalLocation,
  roles,
  identityProvider,
  microFrontend,
  contractorCompany,
});

export default (state, action) => {
  const { appConfig } = state;
  if (action.type === USER_LOGOUT_ACTION) {
    // only persist the appConfig settings when resetting the
    // state of the redux store
    state = {
      appConfig,
    };
  }

  return appReducer(state, action);
};
