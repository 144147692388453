import gql from 'graphql-tag';

export default gql(`
query {
  getRoles {
    name
    displayName
    corporateStructure
    assignable
    createdAt
    updatedAt
  }
}`);
