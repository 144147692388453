import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import forwoodLogo from '../../Images/forwood-logo-blue-on-light.svg';
import { maintenance } from '../../Sass/modules/maintenance.scss';

class MaintenanceMode extends React.PureComponent {
  render() {
    const { t } = this.props;

    return (
      <div className={maintenance}>
        <img src={forwoodLogo} width="50" alt="Forwood ID Logo" />
        {t('systemDown')}
        <br />
        {t('pleaseLogout')}
      </div>
    );
  }
}

MaintenanceMode.defaultProps = {
};

MaintenanceMode.propTypes = {
  t: PropTypes.func,
};

export default withTranslation(['maintenance'])(MaintenanceMode);
