import QueryGetRoles from '../GraphQL/Query-GetRoles';

const ROLES_SET_ACTION = 'ROLES_SET';
const ROLES_CLEAR_ACTION = 'ROLES_CLEAR';

export function rolesDefaultState() {
  return {
    list: [],
    keys: [],
  };
}

export async function getRoles(dispatch, client) {
  const { data } = await client.query({
    query: QueryGetRoles,
    fetchPolicy: 'network-only',
    variables: {},
  });

  dispatch({
    type: ROLES_SET_ACTION,
    payload: data.getRoles,
  });
}

export function clearRoles(dispatch) {
  dispatch({
    type: ROLES_CLEAR_ACTION,
  });
}

export default (state = rolesDefaultState(), action) => {
  switch (action.type) {
    case ROLES_SET_ACTION:
      const { payload } = action;

      state.list = payload.map(r => ({
        name: r.name,
        displayName: r.displayName,
        corporateStructure: r.corporateStructure,
        assignable: r.assignable,
      }));

      state.keys = payload.map(r => r.name);

      return {
        ...state,
      };

    case ROLES_CLEAR_ACTION:
      return rolesDefaultState();

    default:
      return state;
  }
};
