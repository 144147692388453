import QueryGetCompanyDataForCurrentUser from '../GraphQL/Query-GetCompanyDataForCurrentUser';

const COMPANY_CLEAR_ACTION = 'COMPANY_CLEAR';
const COMPANY_DATA_SET_ACTION = 'COMPANY_DATA_SET_ACTION';

export function companyDefaultState() {
  return {
    structure: {},
    flatData: {},
    corporateCompanies: [],
    highestLevelCompanyId: null,
  };
}

export async function getCompanyDataForCurrentUser(dispatch, client) {
  const { data } = await client.query({
    query: QueryGetCompanyDataForCurrentUser,
    fetchPolicy: 'network-only',
  });

  const parseData = JSON.parse(data.getCompanyDataForCurrentUser.data);

  dispatch({
    type: COMPANY_DATA_SET_ACTION,
    structure: parseData.companyStructure,
    flatData: parseData.flatDataHashedById,
    corporateCompanies: parseData.corporateCompanies ? parseData.corporateCompanies : [],
  });
}

export function clearCompany(dispatch) {
  dispatch({
    type: COMPANY_CLEAR_ACTION,
  });
}

export default (state = companyDefaultState(), action) => {
  switch (action.type) {
    case COMPANY_CLEAR_ACTION:
      return companyDefaultState();

    case COMPANY_DATA_SET_ACTION:
      const { structure, flatData, corporateCompanies } = action;
      state.structure = structure;
      state.flatData = flatData;
      state.corporateCompanies = corporateCompanies;
      // Set this value as the corporate company ID for all non master companies
      // If this is the master company (corporate_company == -1) use it's own ID
      state.highestLevelCompanyId = structure.corporate_company !== -1 ? structure.corporate_company : structure.id;
      return {
        ...state,
      };

    default:
      return state;
  }
};
