
export const superAdminRole = 'SuperAdmin';
export const forwoodIdAdminRole = 'ForwoodIdAdmin';
export const siteAdminRole = 'SiteAdmin';

export const roleHierarchy = [
  superAdminRole,
  forwoodIdAdminRole,
  siteAdminRole,
];
