import QueryGetPhysicalLocations from '../GraphQL/Query-GetPhysicalLocations';

const PHYSICAL_LOCATIONS_SET_ACTION = 'PHYSICAL_LOCATIONS_SET';
const PHYSICAL_LOCATIONS_CLEAR_ACTION = 'PHYSICAL_LOCATIONS_CLEAR';

export function physicalLocationDefaultState() {
  return {
    all: [],
  };
}

export async function getPhysicalLocations(dispatch, client, companyId) {
  const { data } = await client.query({
    query: QueryGetPhysicalLocations,
    variables: {
      id: companyId,
      includeSubLevelCompanies: true,
      traverseSiteStructure: true,
    },
  });

  dispatch({
    type: PHYSICAL_LOCATIONS_SET_ACTION,
    payload: data.getPhysicalLocations,
  });
}

export function clearPhysicalLocations(dispatch) {
  dispatch({
    type: PHYSICAL_LOCATIONS_CLEAR_ACTION,
  });
}

export default (state = physicalLocationDefaultState(), action) => {
  switch (action.type) {
    case PHYSICAL_LOCATIONS_SET_ACTION:
      const { payload } = action;

      state.all = payload;

      return {
        ...state,
      };

    case PHYSICAL_LOCATIONS_CLEAR_ACTION:
      return physicalLocationDefaultState();

    default:
      return state;
  }
};
