import React from 'react';
import PropTypes from 'prop-types';
import compare from 'semver-compare';
import { Tag, notification } from 'antd';

import Config from '../../Config/Config';
import styles from './styles.scss';

class Version extends React.Component {
  static get value() {
    return Config.reactApp.VERSION;
  }

  constructor(props) {
    super(props);

    this.state = {
      versionMismatch: false,
    };

    this.notificationKey = 'version';
  }

  componentDidMount() {
    this.compareVersions();
  }

  componentDidUpdate(prevProps) {
    const { notifyNewVersion } = this.props;
    const { versionMismatch } = this.state;

    // we have a mismatching version and the props haven't changed..
    // nothing further to do
    if (versionMismatch && notifyNewVersion === prevProps.notifyNewVersion) {
      return;
    }

    this.compareVersions();
  }

  componentWillUnmount() {
    notification.close(this.notificationKey);
  }

  compareVersions() {
    const { t, notifyNewVersion, appVersion } = this.props;

    // Compare the appVersion (served from the API) with the UI version (from config).
    // Only show the notification if the version coming from the getAppConfig
    // call is greater than what the UI has because the API call is cached so
    // it is a likely scenario that the browser version will be ahead
    // of what is coming back for a short period of time.
    //
    // The shortfall here is if a version is rolled back and the UI needs to
    // catch up, the notification won't be shown (edge case).
    if (appVersion && compare(appVersion, Version.value) === 1) {
      this.setState({
        versionMismatch: true,
      });

      if (notifyNewVersion) {
        this.notify(
          t('common:versionNotificationTitle'),
          t('common:versionNotificationText'),
        );
      }
    }
  }

  notify(message, description) {
    notification.info({
      message,
      description,
      duration: 0,
      key: this.notificationKey,
      className: styles.versionNotification,
    });
  }

  render() {
    const { t } = this.props;

    if (!Version.value) {
      return null;
    }

    return (
      <div className={styles.version}>
        <Tag className="version-tag">
          {`${t('common:versionLabel')} ${Version.value}`}
        </Tag>
      </div>
    );
  }
}

Version.defaultProps = {
  notifyNewVersion: true,
};

Version.propTypes = {
  appVersion: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  notifyNewVersion: PropTypes.bool,
};

export default Version;
