import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  intercomMessenger, configServiceFetchRequest, wsConnect, wsDisconnect,
} from 'componentlibrary';
import { Authenticator } from 'aws-amplify-react';
import Amplify, { ConsoleLogger as Logger, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spin, Modal } from 'antd';
import Config from './Config/Config';
import ForwoodSignIn from './Components/Auth/ForwoodSignIn';
import ForwoodForgotPassword from './Components/Auth/ForwoodForgotPassword';
import ForwoodVerifyContact from './Components/Auth/ForwoodVerifyContact';
import ForwoodRequireNewPassword from './Components/Auth/ForwoodRequireNewPassword';
import { AbilityContext } from './Components/AbilityContext';
import MaintenanceMode from './Components/MaintenanceMode';
import Ability, { defineAbilityForUser } from './Permission/Ability';
import { setAuth, clearAuth, setLocale } from './reducers/auth';
import { isDevelopmentMode, getIn } from './Utils/Utils';
import { getSiteName } from './Utils/AppsDirectoryHelper';
import QuicksightLogin from './Containers/QuicksightLogin';
import { isSelfServiceFederatedUser } from './Utils/Auth';
import {
  storeCookieLang, getCookieKeyForLocale, getCookieLangFromID, getCookieValue,
  clearCookie,
} from './Utils/CookieData';
import { loader } from './Sass/modules/loader.scss';
import { localeMapper } from './Utils/LocaleData';
import { getAppConfig } from './reducers/appConfig';

const App = React.lazy(() => import('./App'));

// Manual configuration
const amplifyConfig = {
  Auth: {
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: Config.reactApp.COOKIE_DOMAIN,
      path: '/',
      expires: 1,
      secure: Config.reactApp.SECURE_PROTOCOL,
      sameSite: 'none',
    },
    oauth: {
      domain: `${Config.cognito.USERPOOL_HOSTED_DOMAIN}.auth.${Config.cognito.REGION}.amazoncognito.com`,
      scope: ['openid', 'email'],
      redirectSignIn: Config.cognito.USERPOOL_ADFS_CALLBACK_URL,
      redirectSignOut: Config.cognito.USERPOOL_ADFS_LOGOUT_URL,
      // responseType 'code' for Authorization code grant, 'token' for Implicit grant
      responseType: 'code',
    },
  },
  Analytics: {
    disabled: true,
    autoSessionRecord: false,
  },
};

Amplify.configure(amplifyConfig);

const logger = new Logger('AppWithAuth');

class AppWithAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: props.auth ? props.auth.authState : null,
      authData: props.auth ? props.auth.authData : null,
      isRedirecting: false,
      ability: Ability,
      setAbility: this.setAbility,
      isGettingUserAttributes: false,
      authReady: false,
      appConfig: null,
      selfServiceIsInProgress: false,
    };

    this.federationLoginTimeout = null;
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
    this.reinitialiseFederatedAuth = this.reinitialiseFederatedAuth.bind(this);
    this.setAbility = this.setAbility.bind(this);

    // Process url param for redirect_uri logic
    const result = this.handleURLParam(props);
    this.state.redirectURI = result.redirectURI;
    this.state.federatedLogin = result.federatedLogin;

    if (this.handleSsoLoginOnRevisitFlag()) {
      this.state.selfServiceIsInProgress = true;
    }

    Hub.listen('forwood', (data) => {
      const { payload } = data;
      this.forwoodChannelEventLister(payload);
    });

    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.authChannelEventLister(payload);
    });

    // Clear any existing isEulaAccepted localStorage
    localStorage.removeItem('isEulaAccepted');
  }

  // Checks the CSRF token flag cookie to see if a HttpOnly cookie exists
  // (used to determine whether or not we are ready to redirect)
  get hasCsrfTokenFlag() {
    return document.cookie.includes(
      `ForwoodID.${Config.cognito.APP_CLIENT_ID}.flag`,
    );
  }

  get showRedirectLoader() {
    const redirectUri = this.state.redirectURI || this.getSearchParam('redirect_uri');

    // there is no redirectUri or we are going to the root of the application, don't show
    if (!redirectUri || redirectUri === '/') {
      return false;
    }

    // we are going to /quicksight-login or it's an absolute path, show the loader
    if (redirectUri.includes('quicksight-login') || redirectUri.includes('sso-login') || redirectUri[0] !== '/') {
      return true;
    }

    return false;
  }

  get pathnameParts() {
    const { pathname = '' } = window.location;
    return pathname.split('/').filter(x => x);
  }

  // expects /sso-login or /sso-login/:identityProvider
  get isSsoLoginRoute() {
    const parts = this.pathnameParts;
    return parts.length <= 2 && parts[0] === 'sso-login';
  }

  get identityProvider() {
    // this is not a direct SSO login via URL, return the default provider
    if (!this.isSsoLoginRoute) {
      return Config.samlProvider.NAME;
    }

    const parts = this.pathnameParts;

    // there is no explicit identityProvider in the URL, return the default provider
    if (parts.length === 1) {
      return Config.samlProvider.NAME;
    }

    return parts[1];
  }

  get supportedIdentityProviders() {
    return Config.samlProvider.SUPPORTED_LIST.split(',');
  }

  get hasLoginCookieSession() {
    return this.checkCookieName('amplify-signin-with-hostedUI') !== null && document.cookie.indexOf(Config.cognito.APP_CLIENT_ID) > -1;
  }

  validateIdentityProvider(providerName) {
    if (!this.supportedIdentityProviders.includes(providerName)) {
      return {
        valid: false,
        error: 'IDENTITY_PROVIDER_NOT_SUPPORTED',
      };
    }

    return {
      valid: true,
    };
  }

  launchHostedUI(identityProvider = this.identityProvider) {
    let target = `${Config.cognito.USERPOOL_ADFS_LAUNCH_URL}&identity_provider=${identityProvider}`;
    const result = this.validateIdentityProvider(identityProvider);
    if (!result.valid) {
      target = `/?error_description=${result.error}&error=client_error`;
    }
    window.location.assign(target);
  }

  handleSsoLoginOnRevisitFlag() {
    const flagValue = localStorage.getItem('ssoLoginOnRevisit');

    if (!flagValue) {
      return false;
    }

    localStorage.removeItem('ssoLoginOnRevisit');

    const data = JSON.parse(flagValue);
    const now = new Date();

    if (now.getTime() < data.expires) {
      this.launchHostedUI(data.providerName);
      return true;
    }
    return false;
  }

  async componentWillReceiveProps(nextProps) {
    const { t } = nextProps;
    if (nextProps.auth && nextProps.auth.authState !== this.props.auth.authState) {
      const { authState, authData } = nextProps.auth;
      if (authState !== 'signedIn') {
        // Prevent intermittent login form issue for the self-service feature
        const ssoLoginOnRevisitFlag = localStorage.getItem('ssoLoginOnRevisit');
        // If this flag is on, set selfServiceIsInProgress to true, so that we don't render anything
        if (ssoLoginOnRevisitFlag) {
          this.setState({ selfServiceIsInProgress: true });
        }
      }

      this.setState({ authState, authData });
      if ((isDevelopmentMode() || this.hasCsrfTokenFlag) && authState === 'signedIn') {
        const idTokenPayload = authData.getSignInUserSession().getIdToken().decodePayload();
        if (!isSelfServiceFederatedUser(authData.signInUserSession)) {
          // Redirect is not supported for non-provision user yet
          this.handleRedirect(authState, idTokenPayload.eula_required ? idTokenPayload.eula_required : false, idTokenPayload.locale, idTokenPayload);
        }
      }
    }

    // only show an error if there is a failure on the first call to app config
    if (!this.state.appConfig && nextProps.appConfig && nextProps.appConfig.error) {
      return this.showError(t('common:genericError'));
    }

    if (!this.state.appConfig || JSON.stringify(nextProps.appConfig) !== JSON.stringify(this.props.appConfig)) {
      this.setAppConfig(nextProps.appConfig);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.federatedLogin.hasError && prevState.federatedLogin.hasError !== this.state.federatedLogin.hasError) {
      this.showFederatedLoginError(this.state.federatedLogin.hasError);
    }
  }

  componentWillMount() {
    const { federatedLogin } = this.state;

    if (!federatedLogin.isAuthenticating) {
      return;
    }

    // If federatedLogin is in progress, dispatch a timeout function
    // so that if for some reason aws-amplify took more 1 mins to resolve the granted code, display error message
    // ideally this should never happen
    this.federationLoginTimeout = setTimeout(() => {
      this.setState({
        federatedLogin: {
          isAuthenticating: false,
          hasError: true,
        },
      });
      logger.error('Federated login timeout!');
    }, 60000);
  }

  async componentDidMount() {
    if (this.state.federatedLogin.hasError) {
      this.showFederatedLoginError(this.state.federatedLogin.hasError);
    }
    await this.getAppConfig();
    this.appConfigInterval = setInterval(this.getAppConfig.bind(this), 60 * 1000);
    await this.props.configServiceFetchRequest(Config.configurationService.URL);
    this.props.wsConnect(Config.configurationService.WEBSOCKET);
  }

  componentWillUnmount() {
    clearInterval(this.appConfigInterval);
    this.props.wsDisconnect();
  }

  getSearchParam(param) {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has(param)) {
      return params.get(param);
    }
  }

  async setCsrfToken(token) {
    await fetch('/csrf-token', {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientId: Config.cognito.APP_CLIENT_ID,
      }),
    });
  }

  async clearCsrfToken() {
    await fetch('/csrf-token', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientId: Config.cognito.APP_CLIENT_ID,
      }),
    });
  }

  forwoodChannelEventLister(payload) {
    if (payload.event === 'federatedLogin') {
      const location = window.location;
      const params = new URLSearchParams(location.search);

      // only handle the `redirect_uri` param if we're on the root or /sso-login.
      // eg: /?redirect_uri=https://abc or /sso-login?redirect_uri=https://xyz
      if (params.has('redirect_uri') && (location.pathname === '/' || this.isSsoLoginRoute)) {
        localStorage.setItem('redirecturi', params.get('redirect_uri'));
        // otherwise store the pathname and any search params for redirection
        // after authentication *except* for special paths eg: /sso-login
        // where we do not wish to do any redirection once authenticated
      } else if (!this.isSsoLoginRoute) {
        localStorage.setItem('redirecturi', location.pathname + location.search);
      }
    }
  }

  async authChannelEventLister(payload) {
    const { event } = payload;

    if (event === 'signIn') {
      const session = await Auth.currentSession();

      if (isSelfServiceFederatedUser(session)) {
        // Redirect is not supported for non-provision user yet
        return;
      }

      // show the preliminary redirecting view while we make any following
      // synchronous API calls
      if (this.showRedirectLoader) {
        this.setState({ isRedirecting: true });
      }

      await this.setCsrfToken(session.getIdToken().getJwtToken());
      const idTokenPayload = session.getIdToken().decodePayload();

      return this.handleRedirect('signedIn', idTokenPayload.eula_required ? idTokenPayload.eula_required : false, idTokenPayload.locale, idTokenPayload);
    } if (event === 'signOut') {
      await this.clearCsrfToken();
      return;
    }

    if (!this.state.federatedLogin.isAuthenticating) {
      return;
    }

    if (event === 'signIn_failure') {
      this.setState({
        federatedLogin: {
          isAuthenticating: false,
          hasError: true,
        },
      });
      logger.error('signIn_failure', payload);
      clearTimeout(this.federationLoginTimeout);
    }
  }

  async getAppConfig() {
    this.props.getAppConfig();
  }

  setAppConfig(appConfig) {
    this.setState({ appConfig });
  }

  setAuth(authState, authData) {
    this.props.setAuth(authState, authData);
  }

  clearAuth() {
    this.props.clearAuth();
  }

  handleURLParam(props) {
    const result = {
      redirectURI: null,
      federatedLogin: {
        isAuthenticating: false,
        hasError: false,
      },
    };

    const { location } = props;
    const savedRedirectURI = localStorage.getItem('redirecturi');

    // Clear redirecturi
    localStorage.removeItem('redirecturi');

    if (location.pathname !== '/') {
      // it is not the root path return
      return result;
    }

    const params = new URLSearchParams(location.search);

    if (params.has('error') && params.has('error_description')) {
      const errorDescription = params.get('error_description');

      if (errorDescription.includes('ACCOUNT_EXIST_BUT_SSO_NOT_ENABLED')) {
        result.federatedLogin.hasError = 'login:ssoNotEnabledForIdAccount';
        return result;
      }

      if (errorDescription.includes('FORWOOD_ID_ACCOUNT_NOT_FOUND')
        || errorDescription.includes('SIGN_UP_REQUEST_DENIED')) {
        result.federatedLogin.hasError = 'login:forwoodIdAccountNotFound';
        return result;
      }

      if (errorDescription.includes('ACCOUNT_NOT_PERMITTED_WITH_IDENTITY_PROVIDER')) {
        result.federatedLogin.hasError = 'login:accountNotPermittedWithIdentityProvider';
        return result;
      }

      if (errorDescription.includes('IDENTITY_PROVIDER_NOT_SUPPORTED')) {
        result.federatedLogin.hasError = 'login:identityProviderNotSupported';
        return result;
      }

      if (errorDescription.includes('MISSING_IDENTITY_LINK_ADDED_TO_COGNITO')) {
        // We intercepted Cognito's automatic signup and added a missing identity link:
        // trigger a fresh federated auth to log the user in successfully with new link in place
        this.reinitialiseFederatedAuth(savedRedirectURI);
      } else {
        // Some other error
        logger.error(errorDescription);
        result.federatedLogin.hasError = true;
      }

      return result;
    }

    if (params.has('redirect_uri')) {
      // redirect_uri param detected directly in the url
      result.redirectURI = params.get('redirect_uri');
      return result;
    }

    // If code param is present, that mean user has been redirected back from cognito hosted UI
    if (params.has('code')) {
      result.federatedLogin.isAuthenticating = true;
      if (savedRedirectURI) {
        result.redirectURI = savedRedirectURI;
      }
    }
    return result;
  }

  reinitialiseFederatedAuth(savedRedirectURI) {
    if (savedRedirectURI) {
      localStorage.setItem('redirecturi', savedRedirectURI);
    }

    this.launchHostedUI();
  }

  handleRedirect(authState, eulaRequired, locale, idTokenPayload = {}) {
    const { redirectURI, appConfig } = this.state;
    const {
      history, i18n, t,
    } = this.props;

    if (authState !== 'signedIn') {
      return;
    }
    const username = idTokenPayload['cognito:username'] || '';
    const cookieKey = getCookieKeyForLocale(username);
    const currentLocale = getCookieValue('tempLocale') || getCookieLangFromID(getCookieKeyForLocale(username)) || locale;

    i18n.changeLanguage(currentLocale);
    storeCookieLang(cookieKey, localeMapper(currentLocale), Config.reactApp.COOKIE_DOMAIN);
    clearCookie('tempLocale', window.location.hostname);

    // storing this in local storage just so that in future we can use it in othe rplaces as well if required
    // EULA: if feature enabled, & idToken attribute indicates user display/acceptance required, redirect

    if (appConfig.eulaEnabled === true && eulaRequired) {
      // Store any redirect_uri, if present, in local storage so it can be redirected back to on eula acceptance
      // ... and reset local redirect state for this component
      if (redirectURI) {
        localStorage.setItem('redirecturi', redirectURI);
        this.setState({ isRedirecting: false, redirectURI: null });
      }
      history.push('/eula');
      return;
    }

    if (redirectURI && redirectURI !== '/') {
      if (redirectURI[0] === '/') {
        // Relative
        this.changeRoute(redirectURI);
        return;
      }

      const updatedRedirectURL = new URL(redirectURI);
      // Get the hostname
      const hostname = updatedRedirectURL.hostname;

      if (
        appConfig
          && appConfig.forwoodAllowedRedirctURLS && appConfig.forwoodAllowedRedirctURLS.length > 0
          && appConfig.forwoodAllowedRedirctURLS.some(domain => !hostname.includes(domain))
      ) {
        this.showError(t('common:RedirectUrlNotSupportedError'), true);
        return;
      }


      // Absolute
      this.setState({ isRedirecting: true });
      window.location.href = redirectURI;
    }
  }

  changeRoute(path) {
    if (this.state.isRedirecting) {
      this.setState({ isRedirecting: false, redirectURI: null });
    }

    this.props.history.push(path);
  }

  handleAuthStateChange(authState, authData) {
    this.validateAuthData(authData, authState).then(async (validateData) => {
      if (validateData === null) {
        this.clearAuth();
      } else {
        const newState = {};
        if (authState === 'signedIn') {
          newState.ability = defineAbilityForUser(authData, getIn(this, ['state', 'appConfig', 'enableSiteAdminBatchProcess']));
          if (this.state.federatedLogin.isAuthenticating) {
            newState.federatedLogin = {
              isAuthenticating: false,
              hasError: false,
            };
          }

          if (typeof authData.attributes === 'undefined') {
            this.setState({ isGettingUserAttributes: true });
            await this.updateAuthDataWithUserAttributes(authData);
            newState.isGettingUserAttributes = false;
          }

          this.setState(newState);
          this.setAuth(authState, authData);
        }
      }
    });
  }

  async updateAuthDataWithUserAttributes(authData) {
    await Auth.currentSession();
    const currentUser = await Auth.currentAuthenticatedUser();
    authData.attributes = currentUser.attributes;
  }

  setAbility(ability) {
    if (!ability) {
      return;
    }

    this.setState({
      ability,
    });
  }

  // Return null only when authData is expired and we can't refresh it
  async validateAuthData(authData, state) {
    if (typeof authData === 'undefined' || authData === null || state !== 'signedIn' || authData.getSignInUserSession()) {
      return authData;
    }

    try {
      // currentSession method will refresh the session if it expired
      await Auth.currentSession();
      const authData = await Auth.currentAuthenticatedUser();

      if (authData && authData.getSignInUserSession()) {
        return authData;
      }
    } catch (err) {
      logger.error(err);
      return null;
    }

    return null;
  }

  federatedLogin() {
    Hub.dispatch('forwood', { event: 'federatedLogin' });
    this.launchHostedUI();
  }

  checkCookieName(name) {
    return document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  }

  showFederatedLoginError(errorMessage) {
    const { t } = this.props;

    let message = 'login:ssoGenericError';
    if (typeof errorMessage === 'string') {
      message = errorMessage;
    }

    this.showError(t(message));
  }

  showError(errorMessage, showokbutton = false) {
    Modal.error({
      title: 'Error',
      content: errorMessage,
      keyboard: false,
      centered: true,
      okButtonProps: {
        style: { display: showokbutton ? 'block' : 'none' },
      },
    });
  }

  appView() {
    const { authState, authData } = this.state;
    clearTimeout(this.federationLoginTimeout);

    if (this.props.location.pathname === '/quicksight-login') {
      return (
        <AbilityContext.Provider value={this.state.ability}>
          <QuicksightLogin
            authData={authData}
            location={this.props.location}
          />
        </AbilityContext.Provider>
      );
    }

    return (
      <AbilityContext.Provider value={this.state.ability}>
        <Suspense fallback={<React.Fragment />}>
          <App
            {...this.props}
            setAbility={this.setAbility}
            authState={authState}
            authData={authData}
          />
        </Suspense>
      </AbilityContext.Provider>
    );
  }

  authenticatorView(loading) {
    // Check the local session storage if session is already present redirect to /logout
    if (this.isSsoLoginRoute) {
      if (this.hasLoginCookieSession) {
        window.location.assign('/logout');
        return;
      }
      this.federatedLogin();
      return;
    }

    const { appConfig } = this.props;
    const { customBrandingConfig, logoUrl } = appConfig;
    const currentCookieLanguage = getCookieValue('tempLocale');

    return (
      <>
        <Authenticator
          {...this.props}
          hideDefault
          onStateChange={this.handleAuthStateChange}
        >
          <ForwoodSignIn
            loading={loading}
            ssoEnabled={appConfig.ssoEnabled}
            customBrandingConfig={customBrandingConfig}
            identityProvider={this.identityProvider}
            logoUrl={logoUrl}
          />
          <ForwoodVerifyContact
            customBrandingConfig={customBrandingConfig}
            logoUrl={logoUrl}
          />
          <ForwoodForgotPassword
            customBrandingConfig={customBrandingConfig}
            logoUrl={logoUrl}
          />
          <ForwoodRequireNewPassword
            customBrandingConfig={customBrandingConfig}
            logoUrl={logoUrl}
          />
        </Authenticator>
        {appConfig.intercomEnabled && intercomMessenger(Config.reactApp.HOSTNAME, currentCookieLanguage)}
      </>
    );
  }

  redirectingView() {
    const { t } = this.props;
    const { redirectURI } = this.state;

    if (!redirectURI) {
      return null;
    }

    const siteName = getSiteName(redirectURI);

    let redirectingText = `${t('redirecting')} ${siteName}`;

    if (siteName === 'Forwood ID Authorizer') {
      redirectingText = t('common:authorizing');
    }

    return (
      <Spin
        className={loader}
        size="large"
        tip={redirectingText}
      >
        <div />
      </Spin>
    );
  }

  render() {
    const {
      appConfig,
      authState,
      federatedLogin,
      isRedirecting,
      isGettingUserAttributes,
      selfServiceIsInProgress,
    } = this.state;

    if (!appConfig) {
      return null;
    }

    const signedIn = (authState === 'signedIn');

    const loading = federatedLogin.isAuthenticating || isGettingUserAttributes;

    if (appConfig.maintenanceMode) {
      return (<MaintenanceMode />);
    }

    if (selfServiceIsInProgress) {
      return (
        <React.Fragment />
      );
    }

    if (isRedirecting) {
      return this.redirectingView();
    }
    return (
      <React.Fragment>
        {signedIn ? this.appView() : this.authenticatorView(loading)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  appConfig: state.appConfig,
  auth: state.auth,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  getAppConfig: (...args) => getAppConfig(dispatch, ...args),
  setAuth: (...args) => setAuth(dispatch, ...args),
  clearAuth: (...args) => clearAuth(dispatch, ...args),
  setLocale: value => setLocale(value, dispatch),
  wsConnect: value => dispatch(wsConnect(value)),
  configServiceFetchRequest: value => dispatch(configServiceFetchRequest(value)),
  wsDisconnect: () => dispatch(wsDisconnect()),
});

AppWithAuth.propTypes = {
  auth: PropTypes.object,
  appConfig: PropTypes.object,
  setAuth: PropTypes.func,
  clearAuth: PropTypes.func,
  setLocale: PropTypes.func,
  configuration: PropTypes.func,
  getAppConfig: PropTypes.func,
  wsConnect: PropTypes.func,
  configServiceFetchRequest: PropTypes.func,
  wsDisconnect: PropTypes.func,
};

export default withTranslation(['login'])(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppWithAuth),
));
