export function isDevelopmentMode() {
  return process.env.NODE_ENV === 'development';
}

export const equalArrays = (_arr1, _arr2) => {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
    return false;
  }

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

// Does 'haystack' array contain at least one of 'needles' array items
// Note: IE-safe function using indexOf & avoiding includes
export function containsAtLeastOne(haystack, needles) {
  if (!haystack || !needles) return false;

  return needles.some(needle => haystack.indexOf(needle) >= 0);
}

// util for resolving deeply nested objects.
// usage: getIn(state, ['auth', 'authData'], false);
// equates to: state && state.auth && state.auth.authData || false;
export function getIn(root, props, defaultValue) {
  let resolved = Object.assign({}, root);

  for (const prop of props) {
    resolved = resolved[prop];
    if (resolved == null) {
      return defaultValue;
    }
  }

  return resolved;
}

export const nextTick = then => setTimeout(then, 1);

export const delay = ms => new Promise(res => setTimeout(res, ms));

/*
* Build query string from the input parameters
*/
export function buildURL(params, baseURL) {
  if (params == null) {
    return baseURL;
  }

  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');

  if (!query) {
    return baseURL;
  }

  if (baseURL) {
    const prefix = baseURL.indexOf('?') === -1 ? '?' : '&';
    return `${baseURL}${prefix}${query}`;
  }

  return query;
}

export function getUserDisplayName(user) {
  if (!user) {
    return '';
  }

  let firstName = '';

  if (typeof user.preferredName !== 'undefined' && user.preferredName) {
    firstName = user.preferredName;
  } else {
    firstName = user.firstName;
  }

  return `${firstName} ${user.lastName}`;
}

// reload the current route (and fetch data) without doing a browser refresh
export function refreshRoute(replace, current) {
  replace('/');
  setTimeout(() => replace(current));
}

export const convertStringToLowerCase = str => str.toLowerCase();
