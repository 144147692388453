import QueryGetIdentityProviders from '../GraphQL/Query-GetIdentityProviders';

const IDENTITY_PROVIDER_SET_ACTION = 'IDENTITY_PROVIDER_SET_ACTION';
const IDENTITY_PROVIDER_SELECT_ACTION = 'IDENTITY_PROVIDER_SELECT_ACTION';
const IDENTITY_PROVIDER_SET_AVAILABLE_ACTION = 'IDENTITY_PROVIDER_SET_AVAILABLE_ACTION';
const IDENTITY_PROVIDER_CLEAR_ACTION = 'IDENTITY_PROVIDER_CLEAR';

export function identityProviderDefaultState() {
  return {
    list: [],
    selected: null,
    availableForSelection: undefined,
  };
}

export function extractIdentityProviderName(user) {
  if (!user.federatedIdentityDetails) {
    return null;
  }

  const data = JSON.parse(user.federatedIdentityDetails);
  if (!data || !data.providers) {
    return null;
  }

  return data.providers[0];
}

export async function getIdentityProviders(dispatch, client) {
  const { data } = await client.query({
    query: QueryGetIdentityProviders,
    fetchPolicy: 'network-only',
  });

  const payload = JSON.parse(data.getIdentityProviders.data);

  dispatch({
    type: IDENTITY_PROVIDER_SET_ACTION,
    payload,
  });
}

export function clearIdentityProviders(dispatch) {
  dispatch({
    type: IDENTITY_PROVIDER_CLEAR_ACTION,
  });
}

export function selectIdentityProvider(dispatch, providerName) {
  dispatch({
    type: IDENTITY_PROVIDER_SELECT_ACTION,
    payload: providerName,
  });
}

export function setAvailableIdentityProvidersCount(dispatch, count) {
  dispatch({
    type: IDENTITY_PROVIDER_SET_AVAILABLE_ACTION,
    payload: count,
  });
}

export default (state = identityProviderDefaultState(), action) => {
  const { payload } = action;
  switch (action.type) {
    case IDENTITY_PROVIDER_SET_ACTION:
      return {
        ...state,
        list: payload.map(item => (
          {
            name: item.provider_name,
            companyId: item.company_id,
            accessibleToSubLevels: item.accessible_to_sublevels,
            samlEmailAttributeName: item.saml_email_attribute_name,
            samlSsoIdAttributeName: item.saml_sso_id_attribute_name,
            primaryProvider: item.primary_provider,
            defaultRoles: item.default_roles,
            ssoIdLinkEnabled: item.sso_id_link_enabled,
            accessibleCompanyIds: item.accessible_company_ids,
          }
        )),
      };

    case IDENTITY_PROVIDER_SELECT_ACTION:
      const selected = state.list.filter(p => p.name === payload);

      return {
        ...state,
        selected: selected.length > 0 ? selected[0] : null,
      };

    case IDENTITY_PROVIDER_SET_AVAILABLE_ACTION:
      return {
        ...state,
        availableForSelection: payload,
      };

    case IDENTITY_PROVIDER_CLEAR_ACTION:
      return identityProviderDefaultState();

    default:
      return state;
  }
};
