import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Version from '../../Components/Version';
import { getIn } from '../../Utils/Utils';

const mapStateToProps = (state, ownProps) => ({
  appVersion: getIn(state, ['appConfig', 'appVersion']),
  notifyNewVersion: getIn(state, ['appConfig', 'deploymentInProgress']) === false,
  ...ownProps,
});

export default connect(mapStateToProps)(withTranslation()(Version));
