import React from 'react';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import { ForgotPassword } from 'aws-amplify-react';
import {
  Form, Input, Icon, Button, Card, Divider, Alert, notification,
} from 'antd';
import { withTranslation } from 'react-i18next';
import AuthWrapper from './AuthWrapper';
import styles from './styles.scss';

class ForwoodForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      readOnly: true,
      loading: false,
    };

    this.sendView = this.sendView.bind(this);
    this.submitView = this.submitView.bind(this);
    this.backToSignIn = this.backToSignIn.bind(this);
    this.makeEditable = this.makeEditable.bind(this);
    this.withValidation = this.withValidation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
  }

  // Although the ResetRequired response is returned as a 400 error, Amplify
  // does not pass this through to this.error() for catching so we need to
  // manually detect by checking for the presence of username in authData.
  get passwordResetRequired() {
    const { authData } = this.props;
    return authData && authData.username !== undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.delivery && (this.state.delivery !== prevState.delivery)) {
      const { t } = this.props;
      this.loading(false);
      notification.success({
        message: t('common:success'),
        description: t('forgotPassword:codeSent'),
      });
    }
  }

  // Overridden the default error handler function
  error(error) {
    const { t } = this.props; // i18next withTranslation function
    let message;
    if (error === 'Username cannot be empty') { // Amplify exception
      message = t('login:UsernameEmptyException');
    } else if (error === 'Password cannot be empty') { // Amplify exception
      message = t('login:PasswordEmptyException');
    } else if (error === 'Code cannot be empty') { // Amplify exception
      message = t('forgotPassword:CodeEmptyException');
    } else {
      switch (error.code) {
        case 'CodeMismatchException':
          message = t('forgotPassword:CodeMismatchException');
          break;
        case 'UserNotFoundException':
          message = t('forgotPassword:UserNotFoundException');
          break;
        case 'NotAuthorizedException':
          if (error.message === 'User password cannot be reset in the current state.') {
            message = t('forgotPassword:NotAuthorizedGivenTheCurrentUserState');
          } else {
            // Unmapped message display the raw message
            message = error.message;
          }
          break;
        case 'LimitExceededException':
          message = t('common:LimitExceededException');
          break;
        case 'InvalidPasswordException':
          message = t('forgotPassword:InvalidPasswordException');
          break;
        case 'InvalidParameterException':
          if (error.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
            message = t('forgotPassword:UnverifiedEmailException');
          } else if (error.message.includes('validation error detected:') && error.message.includes('password')) {
            message = t('forgotPassword:InvalidPasswordException');
          } else if (error.message.includes('validation error') && error.message.includes('username')) {
            message = t('common:InvalidUsernameException');
          } else {
            message = t('common:GenericException');
          }
          break;
        default:
          message = t('common:GenericException');
      }
    }
    this.setState({ error: message });
    this.loading(false);
  }

  resetErrorState() {
    this.setState({ error: null });
  }

  resetDeliveryState() {
    this.setState({ delivery: false });
  }

  withValidation(e) {
    e.preventDefault();
    const { form } = this.props;

    form.validateFieldsAndScroll((err) => {
      if (!err) {
        this.loading();
        this.resetErrorState();
        if (this.state.delivery || this.passwordResetRequired) {
          this.inputs.code = this.inputs.code.trim();
          this.submit().then(() => {
            this.loading(false);
          });
        } else {
          this.inputs.username = this.inputs.username.trim().toLowerCase();
          this.send();
        }
      }
    });
  }

  submit() {
    const { t, authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;

    return Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        notification.success({
          message: t('common:success'),
          description: t('forgotPassword:resetSuccess'),
        });

        this.changeState('signIn');
        this.setState({ delivery: null });
      })
      .catch(err => this.error(err));
  }

  send() {
    this.loading();
    this.resetErrorState();
    super.send();
  }

  loading(state = true) {
    this.setState({ loading: state });
  }

  compareToFirstPassword(rule, value, callback) {
    const { form, t } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(t('forgotPassword:PasswordMisMatchException'));
    } else {
      callback();
    }
  }

  makeEditable() {
    if (!this.state.readOnly) {
      return;
    }

    this.setState({ readOnly: false });
  }

  sendView() {
    const { t, form = {} } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{
              required: true, message: t('login:UsernameEmptyException'),
            }],
          })(
            <Input
              prefix={<Icon type="user" className={styles.formIcon} />}
              name="username"
              key="username"
              placeholder={t('usernamePlaceholder')}
              onChange={this.handleInputChange}
            />,
          )}
        </Form.Item>
        <Form.Item className="no-margin-bottom">
          <Button
            type="primary"
            block
            onClick={this.withValidation}
            tabIndex={1}
          >
            {t('forgotPassword:sendCode')}
          </Button>
        </Form.Item>
      </div>
    );
  }

  submitView() {
    const { t, form = {} } = this.props;
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        {this.passwordResetRequired && (
          <Form.Item>
            <Alert message={t('forgotPassword:resetRequired')} type="info" />
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator('code', {
            rules: [{
              required: true, message: t('forgotPassword:CodeEmptyException'),
            }],
          })(
            <Input
              id="code"
              name="code"
              placeholder={t('forgotPassword:code')}
              key="code"
              prefix={<Icon type="safety" className={styles.formIcon} />}
              autoComplete="false"
              onChange={this.handleInputChange}
              onFocus={this.makeEditable}
              readOnly={this.state.readOnly}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: t('login:PasswordEmptyException'),
            },
            {
              pattern: /^(?=.*\d)(?=.*[\^$*.{}()?"!@#%&,><':;|[_\]~`=+-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              message: t('login:PasswordRequirenmentMessage'),
            }],
          })(
            <Input
              prefix={<Icon type="lock" className={styles.formIcon} />}
              placeholder={t('forgotPassword:newPassword')}
              key="password"
              name="password"
              type="password"
              autoComplete="off"
              onChange={this.handleInputChange}
              onFocus={this.makeEditable}
              readOnly={this.state.readOnly}
            />,
          )}

        </Form.Item>

        <Form.Item>
          {getFieldDecorator('confirm password', {
            rules: [
              {
                required: true,
                message: t('login:ConfirmPasswordEmptyException'),
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" className={styles.formIcon} />}
              placeholder={t('forgotPassword:confirmNewPassword')}
              key="confirmPassword"
              name="confirmPassword"
              type="password"
              autoComplete="off"
              onChange={this.handleInputChange}
              onFocus={this.makeEditable}
              readOnly={this.state.readOnly}
            />,
          )}
        </Form.Item>

        <Form.Item className="no-margin-bottom">
          <Button
            type="primary"
            onClick={this.withValidation}
            htmlType="submit"
            tabIndex={1}
            block
          >
            {t('common:submit')}
          </Button>
        </Form.Item>
      </React.Fragment>
    );
  }

  backToSignIn() {
    this.resetErrorState();
    this.resetDeliveryState();
    this.changeState('signIn');
  }

  showComponent() {
    const {
      hide,
      t,
      customBrandingConfig,
      logoUrl,
    } = this.props;
    const { error, loading } = this.state;

    if (hide && hide.includes(ForwoodForgotPassword)) { return null; }

    return (
      <AuthWrapper
        error={error}
        loading={loading}
        customBrandingConfig={customBrandingConfig}
        logoUrl={logoUrl}
      >
        <Form onSubmit={this.withValidation}>
          <Form.Item>
            <Card>
              <Divider className={styles.divider}>
                <span className={styles.title}>
                  {t('forgotPassword:resetPasswordTitle')}
                </span>
              </Divider>
              {this.state.delivery || this.passwordResetRequired ? this.submitView() : this.sendView()}
            </Card>
          </Form.Item>
          {(this.state.delivery || this.passwordResetRequired) && (
            <Form.Item>
              <Card>
                <Divider className={styles.divider}>
                  <span className={styles.title}>
                    {t('forgotPassword:resendCodeTitle')}
                  </span>
                </Divider>
                <Button type="link" block onClick={this.send}>
                  {t('forgotPassword:resendCode')}
                </Button>
              </Card>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              block
              type="secondary"
              onClick={this.backToSignIn}
            >
              {t('backToLogin')}
            </Button>
          </Form.Item>
        </Form>
      </AuthWrapper>
    );
  }
}

ForwoodForgotPassword.propTypes = {
  logoUrl: PropTypes.string,
};

ForwoodForgotPassword.defaultProps = {
  logoUrl: null,
};

export default withTranslation(['login', 'forgotPassword', 'common'])(Form.create()(ForwoodForgotPassword));
