import React from 'react';
import { Route } from 'react-router-dom';

// PropsRoute is a wrapper for <Route /> allowing the ability to pass in
// additional props to the component
function PropsRoute({ component, ...props }) {
  return (
    <Route
      {...props}
      render={routeProps => renderMergedProps(component, routeProps, props)}
    />
  );
}

function renderMergedProps(component, ...props) {
  const mergedProps = Object.assign({}, ...props);
  return (
    React.createElement(component, mergedProps)
  );
}

export default PropsRoute;
