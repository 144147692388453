import React, { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import Ability from '../../Permission/Ability';

export const AbilityContext = createContext(Ability);
export const Can = createContextualCan(AbilityContext.Consumer);

export function withAbilityContext(Component) {
  return function ComponentWithAbility(props) {
    return (
      <AbilityContext.Consumer>
        {ability => <Component {...props} ability={ability} />}
      </AbilityContext.Consumer>
    );
  };
}
