import Auth from '@aws-amplify/auth';
import { getIn } from './Utils';

export const authUserEmail = (authData) => {
  let userEmail = authData && authData.attributes && authData.attributes.email;

  if (!userEmail && authData.getSignInUserSession()) {
    userEmail = authData.getSignInUserSession().getIdToken().decodePayload().email;
  }

  return userEmail;
};

export const authUserRoles = authData => authData.getSignInUserSession().getAccessToken().decodePayload()['cognito:groups'];

export const authUserToken = authData => authData.getSignInUserSession().getAccessToken().getJwtToken();

export const authUserCompanyStructure = authData => authData.getSignInUserSession().getIdToken().decodePayload()['custom:company_uuid'];

export const authUserPhysicalLocation = authData => authData.getSignInUserSession().getIdToken().decodePayload()['custom:phys_location_uuid'];


export function getEmailFromIdToken(authData) {
  return getIn(authData, ['signInUserSession', 'idToken', 'payload', 'email'], null);
}

export function convertIdTokenPayloadToUserObject(idTokenPayload) {
  return {
    cognitoUuid: getIn(idTokenPayload, ['sub'], null),
    roles: getIn(idTokenPayload, ['cognito:groups'], []),
    email: getIn(idTokenPayload, ['email'], null),
    companyStructure: getIn(idTokenPayload, ['custom:company_uuid'], null),
    physicalLocation: getIn(idTokenPayload, ['custom:phys_location_uuid'], null),
    username: getIn(idTokenPayload, ['cognito:username'], null),
    forwoodUuid: getIn(idTokenPayload, ['custom:forwood_uuid'], null),
    ssoId: getIn(idTokenPayload, ['custom:ssoid'], null),
  };
}

export async function refreshIdToken(callback) {
  const user = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  // this method is not a Promise, so it requires an old fashioned callback
  user.refreshSession(currentSession.refreshToken, callback);
}

export function isSelfServiceFederatedUser(session) {
  // federated self-service user won't have forwoodUuid in their token
  const payload = getIn(session, ['idToken', 'payload']);
  if (payload && !payload['custom:forwood_uuid']) {
    return true;
  }
  return false;
}

export function authProviderName(session) {
  const identities = getIn(session, ['idToken', 'payload', 'identities'], []);
  if (identities.length > 0) {
    return identities[0].providerName;
  }
}
