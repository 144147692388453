import React from 'react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { VerifyContact } from 'aws-amplify-react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Radio, Card, Divider, Icon,
} from 'antd';
import { withTranslation } from 'react-i18next';
import AuthWrapper from './AuthWrapper';
import styles from './styles.scss';

const logger = new Logger('VerifyContact');

export class ForwoodVerifyContact extends VerifyContact {
  constructor(props) {
    super(props);
    this.state = { error: null, checkedValue: null, loading: false };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.skip = this.skip.bind(this);
  }

  // Overridden the default error handler function
  error(error) {
    const { t } = this.props;
    let message;
    if (error === 'Neither Email nor Phone Number selected') { // Amplify exception
      message = t('verifyEmail:NoContactSelectedException');
    } else if (error === 'Code cannot be empty') { // Amplify exception
      message = t('forgotPassword:CodeEmptyException');
    } else {
      switch (error.code) {
        case 'CodeMismatchException':
          message = t('forgotPassword:CodeMismatchException');
          break;
        case 'LimitExceededException':
          message = t('common:LimitExceededException');
          break;
        default:
          message = t('common:GenericException');
      }
    }
    this.setState({ error: message });
  }

  resetErrorState() {
    this.setState({ error: null });
  }

  verify() {
    this.resetErrorState();
    super.verify();
  }

  submit() {
    this.resetErrorState();
    super.submit();
  }

  // The default handleInputChange method doesn't work with Radio components
  handleRadioChange(e) {
    const { value, name } = e.target;

    this.inputs = this.inputs || {};
    this.inputs[name] = true;
    this.inputs.checkedValue = value;

    this.setState({ checkedValue: value });
  }

  // Logic of this view is copied directly from aws-amplify-react 0.1.54 version
  // We only change the logic in the return method to custom the UI
  verifyView() {
    const user = this.props.authData;
    const { t } = this.props;
    const { checkedValue } = this.state;
    if (!user) {
      logger.debug('no user for verify');
      return null;
    }
    const { unverified } = user;
    if (!unverified) {
      logger.debug('no unverified on user');
      return null;
    }
    const { email, phone_number: phoneNumber } = unverified;
    return (
      <React.Fragment>
        <Form.Item>
          <Radio.Group value={checkedValue} name="contact" onChange={this.handleRadioChange}>
            {email
              ? (
                <Radio
                  value="email"
                  checked={checkedValue === 'email'}
                >
                  {t('common:email')}
                </Radio>
              ) : null
          }
            {phoneNumber
              ? (
                <Radio
                  value="phone_number"
                  checked={checkedValue === 'phone_number'}
                >
                  {t('phoneNumber')}
                </Radio>
              ) : null
          }
          </Radio.Group>
        </Form.Item>
        <Form.Item className="no-margin-bottom">
          <Button type="primary" block onClick={this.verify}>
            {t('common:submit')}
          </Button>
        </Form.Item>
      </React.Fragment>
    );
  }

  submitView() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Form.Item>
          <Input
            prefix={<Icon type="safety" className={styles.formIcon} />}
            name="code"
            key="code"
            placeholder={t('forgotPassword:code')}
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item className="no-margin-bottom">
          <Button type="primary" block onClick={this.submit}>
            {t('common:submit')}
          </Button>
        </Form.Item>
      </React.Fragment>
    );
  }

  skip() {
    const { authData } = this.props;
    this.resetErrorState();
    this.changeState('signedIn', authData);
  }

  showComponent() {
    const {
      hide,
      t,
      customBrandingConfig,
      logoUrl,
    } = this.props;
    const { error, loading } = this.state;
    if (hide && hide.includes(ForwoodVerifyContact)) { return null; }

    return (
      <AuthWrapper
        error={error}
        loading={loading}
        customBrandingConfig={customBrandingConfig}
        logoUrl={logoUrl}
      >
        <Form>
          <Form.Item>
            <Card>
              <Divider className={styles.divider}>
                <span className={styles.title}>
                  {t('verifyEmailAddress')}
                </span>
              </Divider>
              <p>{t('verifyEmailSubheader')}</p>
              {this.state.verifyAttr ? this.submitView() : this.verifyView()}
            </Card>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="secondary"
              onClick={this.skip}
            >
              {t('common:skip')}
            </Button>
          </Form.Item>
        </Form>
      </AuthWrapper>
    );
  }
}

ForwoodVerifyContact.propTypes = {
  customLogoUrl: PropTypes.string,
};

ForwoodVerifyContact.defaultProps = {
  logoUrl: null,
};

export default withTranslation(['verifyEmail', 'common'])(ForwoodVerifyContact);
