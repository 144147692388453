import 'core-js/stable';
import 'whatwg-fetch';
import './Sass/base.scss';

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import i18n from './Translations/i18n';
import AppWithAuth from './AppWithAuth';
import PropsRoute from './Containers/PropsRoute';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

const Logout = lazy(() => (import('./Components/Logout')));

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Suspense fallback={<React.Fragment />}>
          <AppWithAuth />
          <PropsRoute
            path="/logout"
            component={Logout}
          />
        </Suspense>
      </Provider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

registerServiceWorker();
